.main-content-det {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-col {
  justify-content: center !important;
  max-height: 800px !important;
}

.ant-card-grid .ant-card-grid-hoverable {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

/*.ant-carousel .slick-slider .slick-list .slick-track .slick-slide {
  display: flex;
  justify-content: center !important;
  align-items: center;
  line-height: 250px;
  background: trasparent;
  overflow: hidden;
}*/

.slider-movies {
  height: 768px;
  width: 1024px;

  &__movie {
    height: 768px;
    width: 1024px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;

    &-info {
      display: flex;
      align-items: flex-start;
      height: 15%;
      padding-bottom: 40px;

      > div {
        background-color: #a7a7a7cf;
        margin: 0 auto;
        text-align: center;
        width: 70%;
        padding: 20px;
      }
    }
  }
}
