.row::after {
  content: "";
  clear: both;
  display: table;
}

.form-login {
  display: flex;
  justify-content: center;
}

.login-form {
  width: 330 !important ;
  //margin: 1.5rem 0.3 1.5rem 0.3rem;
}
.login-field {
  justify-content: space-between;
  padding-bottom: 15;
}

.login-button-form {
  display: flex;
  justify-content: space-between;
}

.btn-submit {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.side-backgr-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 95vh;
}

.side-backgr {
  display: flex;
  flex-direction: column;
  //justify-content: space-between;
  height: 95vh;
}

.logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.img-logo {
  height: 250;
}

.wcome-title {
  color: rgba(#6a3e98, 0.8);
  font-size: 1.5rem;
  font-weight: 500;
  margin-left: 1rem;
}

.wcome-subtitle {
  font-size: 1rem;
  font-weight: 200;
  margin: 1.5rem 0 1.5rem 1rem;
  color: rgba(#fff, 0.9);
}

.login-head {
  display: flex;
  flex-direction: row;
  justify-content: center;

  /*.login-head-label {
    color: rgba(#2c2c2c, 0.6);
  }

  .login-head-link {
    color: #387af5;
  }*/
}

.copy-foot {
  color: #66c6f1;
  margin-bottom: 10 !important;
  text-align: center !important;
}

.footer-right {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 30px;
  //margin-right: 15;
}

.side-backgr-div {
  display: flex;
  height: 33.33%;
}

[class*="ant-col"] {
  float: left;
  padding: 15;
}

.aside {
  background-color: #33b5e5;
  padding: 15;
  color: #ffffff;
  text-align: center;
  font-size: 14;
  box-shadow: 0 1 3 rgba(0, 0, 0, 0.12), 0 1 2 rgba(0, 0, 0, 0.24);
}

/* For mobile phones: */
/*[class*="ant-col"] {
  float: left;
  width: 100% !important;
  padding: 15px;
}*/

/*.side-image {
  display: none;
}
.side-login {
  column-span: 24;
}*/

@media only screen and (min-width: 769px) {
  /* For desktop: */
  .side-image {
    display: flex;
  }
  .col-1 {
    width: 8.33%;
  }
  .col-2 {
    width: 16.66%;
  }
  .col-3 {
    width: 25%;
  }
  .col-4 {
    width: 33.33%;
  }
  .col-5 {
    width: 41.66%;
  }
  .col-6 {
    width: 50%;
  }
  .col-7 {
    width: 58.33%;
  }
  .col-8 {
    width: 66.66%;
  }
  .col-9 {
    width: 75%;
  }
  .col-10 {
    width: 83.33%;
  }
  .col-11 {
    width: 91.66%;
  }
  .col-12 {
    width: 100%;
  }
  .img-logo {
    height: 250;
  }
  .wcome-title {
    color: rgba(#6a3e98, 1);
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 0px !important;
    text-align: center !important;
    //margin-left: 1rem;
  }

  .wcome-subtitle {
    font-size: 1rem;
    font-weight: 600;
    text-align: center !important;
    //margin: 2.5rem 0 6.5rem 1rem;
    color: rgba(rgb(17, 7, 7), 0.9);
  }
  .login-form {
    width: 400px;
    margin: 1rem 0 0 0;
  }
}
