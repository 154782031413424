.main-content-b {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90vw;
}

.ant-table-thead .ant-table-cell {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.ant-table-tbody .ant-table-row .ant-table-cell {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.ant-btn-ghost {
  background: rgba(#66c6f1, 1);
  background-color: rgba(#66c6f1, 1);
  border-color: rgba(#66c6f1, 1);
}
