.main-content-det {
  display: flex;
  align-items: center;
  justify-content: center;
}

.inputDiv {
  display: flex;
  flex-direction: row;
  margin-bottom: 17px !important;
}
.inputLine {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 17px !important;
}
