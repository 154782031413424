.main-content-det {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-carousel .slick-slide .slick-list .slick-track {
  text-align: center;
  height: 250;
  width: 790px;
  line-height: 250px;
  background: #364d79;
  overflow: hidden;
}
